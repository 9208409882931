import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('@/views/Team')
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('@/views/Gallery')
  },
  {
    path: '/schedule',
    name: 'Timetable',
    component: () => import('@/views/Timetable')
  },
  {
    path: '/news/:slug',
    name: 'NewsDetail',
    component: () => import('@/views/NewsDetail')
  },
  {
    path: '/support',
    name: 'HomeExtra',
    component: () => import('@/views/Home')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) return savedPosition
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  },
  routes
})

export default router