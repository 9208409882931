import Vue from 'vue'
import Vuex from 'vuex'

import Directus from './modules/directus'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { directus: Directus }
})

export default store
