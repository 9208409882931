import api from '@/plugins/api'
import mutations from '@/store/mutations'
import router from '@/router'

export default {
  state: () => ({
    newsList: [],
    article: {}
  }),
  mutations: {
    ...mutations
  },
  actions: {
    async fetchNews (context) {
      const response = await api.get('/items/news?filter[status][_eq]=published&sort[]=-date_created')
        if (response.data.data.length) context.commit('SET_DIRECTUS_DATA', { newsList: response.data.data })
    },
    async fetchArticle (context, slug) {
      const response = await api.get(`/items/news?filter[url][_eq]=${slug}&filter[status][_eq]=published`)
        if (response.data.data.length) context.commit('SET_DIRECTUS_DATA', { article: response.data.data[0] })
        else router.replace({path: '/news'})
    }
  }
}
