import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Meta from 'vue-meta'
import VueCollapse from 'vue2-collapse'
import store from '@/store'
import '@/assets/styles/style.scss'
import api from './plugins/api'

Vue.prototype.$api = api
Vue.config.productionTip = false

Vue.use(Meta)
Vue.use(VueCollapse)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
