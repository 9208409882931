<template lang="pug">
  .wrapper
    header-block
    main
      router-view
    footer-block
</template>

<script>
export default {
  name: 'App',
  components: {
    FooterBlock: () => import('@/components/FooterBlock.vue'),
    HeaderBlock: () => import('@/components/HeaderBlock.vue')
  },
  metaInfo: {
    title: 'Спортивний клуб «Vidar» | Тренування для молоді: Бокс, Спас, Дзюдо | Запоріжжя'
  }
}
</script>
